import isEmpty from 'lodash/isEmpty';

import { selectCruisesCommonVoyagesDataRegions } from '@/ducks/cruisesCommon/selectors';
import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '@/ducks/filters/types';

import { getSearchParamsValue, toStringArray } from '../utils';

export const destPackages: FiltersConfigValue<FiltersTypes[FILTER_KEY.destPackages]> = {
  getValue: ({ searchParams, state, value }) => {
    const items = toStringArray(value);
    if (items.length > 0) {
      return items;
    }

    const destRegions = toStringArray(getSearchParamsValue(FILTER_KEY.destRegions, searchParams));
    if (destRegions.length === 0) {
      return [];
    }

    if (toStringArray(getSearchParamsValue(FILTER_KEY.destPorts, searchParams)).length > 0) {
      return [];
    }

    return selectCruisesCommonVoyagesDataRegions(state)
      .reduce<(string | undefined)[]>(
        (result, region) =>
          destRegions.includes(region.id) ? [...result, ...region.packages.map(({ id }) => id)] : result,
        [],
      )
      .filter(Boolean) as string[];
  },
  serializeValue: (value) => (!isEmpty(value) ? value : null),
};

export const destRegions: FiltersConfigValue<FiltersTypes[FILTER_KEY.destRegions]> = {
  getValue: ({ value }) => toStringArray(value),
  serializeValue: (value) => (!isEmpty(value) ? value : null),
};

export const destPorts: FiltersConfigValue<FiltersTypes[FILTER_KEY.destPorts]> = {
  getValue: ({ value }) => toStringArray(value),
  serializeValue: (value) => (!isEmpty(value) ? value : null),
};
