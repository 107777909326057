import { pick } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import without from 'lodash/without';

import { selectIsAgencyDataAvailable } from '@/ducks/fm/selectors';
import { selectIsMnvv } from '@/ducks/mnvv/selectors';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';
import searchParamsAsObject from '@/helpers/url/searchParamsAsObject';
import { ensureArray } from '@/helpers/util/misc';

import { config } from './config';
import { FILTER_KEY, type FiltersKey, type FiltersTypes, type GetFilterValuesProps } from './types';

const toArray = (value: unknown) => {
  let arr: unknown[] = [];
  if (Array.isArray(value)) {
    arr = value;
  } else {
    if (typeof value === 'string' && value.indexOf(',') !== -1) {
      arr = value.split(/\s*,\s*/);
    } else {
      arr = [value];
    }
  }

  return arr;
};

export const toInt = (value: unknown) => {
  const int = parseInt(value as string, 10);
  return isNaN(int) ? null : int;
};

export const toIntArray = (value: unknown) => {
  return toArray(value).reduce<number[]>((arr, val) => {
    const int = parseInt(val as string, 10);
    return isNaN(int) ? arr : [...arr, int];
  }, []);
};

export const toStringArray = (value: unknown) => {
  return toArray(value).reduce<string[]>((arr, val) => {
    const str = val?.toString();
    return isEmpty(str) ? arr : [...arr, str!];
  }, []);
};

export const toBoolean = (value: null | unknown) => {
  return value !== null && value !== 'false' && value !== '0';
};

const getDependencies = ({ searchParams, state }: GetFilterValuesProps) => {
  return JSON.stringify({
    isFm: selectIsAgencyDataAvailable(state),
    isMnvv: selectIsMnvv(state),
    searchParams: searchParams.toString(),
  });
};

export const getFilterValues = (props: GetFilterValuesProps) => {
  const { searchParams, state } = props;

  const dependencies = getDependencies(props);
  if (dependencies === state.filters?.dependencies) {
    return state.filters;
  }

  const values = Object.entries(config).reduce((filterValues, [key, { getValue }]) => {
    let value: FiltersTypes[keyof FiltersTypes] | null = getSearchParamsValue(key as FiltersKey, searchParams);
    if (getValue && typeof getValue === 'function') {
      value = getValue({ ...props, value });
    }
    return {
      ...filterValues,
      [key]: value,
    };
  }, {} as FiltersTypes);

  return {
    dependencies,
    values,
  };
};

const mapFilterKeyToSearchParam: { [key in FiltersKey]?: string | string[] } = {
  accessible: ['isCabinAccessible'],
  dateFrom: ['dateFrom', 'fromDate'],
  dateTo: ['dateTo', 'toDate'],
  destPackages: ['selectedPackages', 'selectedPackage'],
  destPorts: ['selectedPorts', 'selectedPort'],
  destRegions: ['selectedRegions', 'selectedRegion', 'selectedRegionsIds'],
  priceMax: ['priceMax', 'maxPrice'],
  priceMin: ['priceMin', 'minPrice'],
  ships: ['ships', 'shipCode', 'selectedShipList'],
  weekend: ['weekend', 'isWeekend'],
};

export const getSearchParamsKeys = (key: FiltersKey) => {
  return ensureArray(mapFilterKeyToSearchParam[key as FiltersKey] || [key]) as FiltersKey[];
};

export const getInvalidFiltersSearchParamsKeys = (props: GetFilterValuesProps) => {
  return (Object.keys(config) as FiltersKey[]).reduce<string[]>((keys, key) => {
    const isValueValidForURL = config[key].isValueValidForURL;
    const value: FiltersTypes[keyof FiltersTypes] | null = getSearchParamsValue(key as FiltersKey, props.searchParams);
    const isValid =
      !isValueValidForURL || (typeof isValueValidForURL === 'function' && isValueValidForURL({ ...props, value }));
    return isValid ? keys : [...keys, ...getSearchParamsKeys(key)];
  }, []);
};

export const getSearchParamsValue = (key: FiltersKey, searchParams: URLSearchParams): null | string | string[] => {
  const foundKey = getSearchParamsKeys(key).find((key) => searchParams.has(key));
  if (foundKey) {
    const values = searchParams.getAll(foundKey);
    return values.length > 1 ? values : values[0]!;
  }

  return null;
};

export const checkIfFilterOverridden = (key: FiltersKey, searchParams: URLSearchParams) => {
  return Boolean(some(getSearchParamsKeys(key), (key) => searchParams.has(key)));
};

export const getAllFiltersKeys = (plus?: string[]) => [
  ...Object.values(FILTER_KEY).reduce<FiltersKey[]>(
    (keys, key) => [...keys, ...getSearchParamsKeys(key as FiltersKey)],
    [],
  ),
  ...(plus || []),
];

export const getAllVoyagesFiltersKeys = (plus?: string[]) => [
  ...without(
    Object.values(FILTER_KEY),
    FILTER_KEY.cabinCategoryCode,
    FILTER_KEY.cabinType,
    FILTER_KEY.sortTypeChooseCabin,
  ).reduce<FiltersKey[]>((keys, key) => [...keys, ...getSearchParamsKeys(key as FiltersKey)], []),
  ...(plus || []),
];

export const getAllFiltersSearchParams = (plus?: string[]) =>
  pick(searchParamsAsObject(getCurrentSearchParams()), getAllFiltersKeys(plus));

export const getAllVoyagesFiltersSearchParams = (plus?: string[]) => {
  const searchParams = searchParamsAsObject(getCurrentSearchParams()) as Record<string, string>;

  return pick(searchParams, getAllVoyagesFiltersKeys(plus));
};
