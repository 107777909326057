import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '@/ducks/filters/types';
import { selectDefaultFilterPriceType } from '@/ducks/filtersOptions';
import isSuitableCabinType from '@/helpers/isSuitableCabinType';
import { FiltersPriceType } from '@/infra/types/common/filters';

import { toInt } from '../utils';

const minPrice: FiltersConfigValue<FiltersTypes[FILTER_KEY.priceMin]> = {
  getValue: ({ value }) => toInt(value),
};

const maxPrice: FiltersConfigValue<FiltersTypes[FILTER_KEY.priceMax]> = {
  getValue: ({ value }) => toInt(value),
};

const priceType: FiltersConfigValue<FiltersTypes[FILTER_KEY.priceType]> = {
  getValue: ({ searchParams, state, value }) => {
    const cabinType = searchParams.get(FILTER_KEY.cabinType);

    if (
      value &&
      Object.values(FiltersPriceType).includes(value as FiltersPriceType) &&
      (!cabinType || !isSuitableCabinType(cabinType))
    ) {
      return value as FiltersPriceType;
    }

    return selectDefaultFilterPriceType(state) || FiltersPriceType.sailorPerNight;
  },
  serializeValue(value, { state }) {
    const defaultPriceType = selectDefaultFilterPriceType(state) || FiltersPriceType.perCabin;
    return !value || value === defaultPriceType ? null : value;
  },
};

export { maxPrice, minPrice, priceType };
