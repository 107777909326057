import { createSelector } from '@reduxjs/toolkit';

import {
  PromoBannerType,
  type TPromoBannerCommon,
  type TPromoBannerMerchandisingProps,
} from '@/ducks/promoBanners/types';
import { type RootState } from '@/store';

export const selectPromoBanners = (state: RootState) => state?.promoBanners?.data;

export const selectPromoBannerPlaces = createSelector(selectPromoBanners, (items) =>
  items.map((item: TPromoBannerCommon) => item?.placeId),
);

export const selectMerchandisingBannerDetails = (state: RootState) =>
  selectPromoBanners(state)?.find(
    (promoData): promoData is TPromoBannerMerchandisingProps => promoData?.type === PromoBannerType.merchandising,
  );
