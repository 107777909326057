import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '@/ducks/filters/types';
import { FiltersSortType } from '@/infra/types/common/filters';

const sortTypeChooseCabin: FiltersConfigValue<FiltersTypes[FILTER_KEY.sortTypeChooseCabin]> = {
  getValue: ({ value }) => {
    if (value && Object.values(FiltersSortType).includes(value as FiltersSortType)) {
      return value as FiltersSortType;
    }

    return FiltersSortType.recommended;
  },
  serializeValue(value) {
    return !value || value === FiltersSortType.recommended ? null : value;
  },
};

export default sortTypeChooseCabin;
