import { useCallback } from 'react';

import cn from 'classnames';

import type { TPromoBannerLeaderboardProps, TWithOnClick } from '@/ducks/promoBanners/types';
import type { TLink } from '@/infra/types/common';

import Arrow from '@/components/Icon/Arrow';
import ImageSet from '@/components/Media/ImageSet';
import { PortHoleWrapper } from '@/components/PromoBanner/Leaderboard/PortHoleWrapper';
import { VstyleWrapper } from '@/components/PromoBanner/Leaderboard/VstyleWrapper';
import verifyValue from '@/components/PromoBanner/helpers/verifyValue';
import { Button } from '@/components/elements';

import styles from './PromoBannerLeaderboard.module.scss';

export const COLORS = {
  _default: { circleColor: '#760504', color1: '#CC0000', color2: '#CC0000' },
  oceanBlue: { circleColor: '#01265C', color1: '#0046AD', color2: '#0046AD' },
  rockstar: { circleColor: '#443B2A', color1: '#867453', color2: '#867453' },
  rockstarDark: { circleColor: '#443B2A', color1: '#000000', color2: '#867453' },
  squidInk: { circleColor: '#170620', color1: '#3C1053', color2: '#3C1053' },
  uplift: { circleColor: '#106059', color1: '#1FAA9F', color2: '#1FAA9F' },
  virginRed: { circleColor: '#760504', color1: '#CC0000', color2: '#CC0000' },
  virginRedDark: { circleColor: '#760504', color1: '#000000', color2: '#CC0000' },
};
export const APPEARANCES = ['standard', 'prominent'];
export const CONTENT_ALIGNS = ['left', 'right', 'center', 'trueRight'];
export const THEMES = ['lightMode', 'darkMode'];
export const DESIGN_STYLE = ['standard', 'vFrame', 'portHole'];

const PromoBannerLeaderboard = (props: TWithOnClick<TPromoBannerLeaderboardProps>) => {
  const {
    appearance: designStyle,
    contentAlign,
    cta,
    description,
    imageSet,
    onClick,
    overlayColor,
    theme,
    title,
  } = props;
  const hasCTA = !!cta?.url && !!cta?.label;
  const isButton = cta?.appearance === 'prominent' && APPEARANCES.includes(cta?.appearance);

  const rootClasses = cn(
    styles.root,
    `_align-${verifyValue(contentAlign, CONTENT_ALIGNS)}`,
    `_theme-${verifyValue(theme, THEMES)}`,
  );
  const color = overlayColor as keyof typeof COLORS;
  const overlayColors = COLORS[color] || COLORS._default;
  const leaderboardStyle = verifyValue(designStyle, DESIGN_STYLE);

  const clickCb = useCallback(() => {
    if (isButton) window.open(cta!.url!, '_blank');
    onClick?.(cta! as TLink);
  }, [cta, isButton, onClick]);

  return (
    <div className="PromoBannerLeaderboard">
      <div className={rootClasses}>
        {!!imageSet?.srcSet && <ImageSet className={styles.picture} {...imageSet} />}
        {leaderboardStyle === 'vFrame' && <VstyleWrapper className={contentAlign} overlayColors={overlayColors} />}
        {leaderboardStyle === 'portHole' && <PortHoleWrapper className={contentAlign} overlayColors={overlayColors} />}
        <div className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          <div className={styles.description}>{description}</div>
          {hasCTA && !isButton && (
            <a className={styles.cta} href={cta.url!} onClick={clickCb} target="_blank">
              {cta.label}
              <Arrow />
            </a>
          )}
          {hasCTA && isButton && (
            <Button className={styles.cta} onClick={clickCb} variant="primary">
              {cta.label}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromoBannerLeaderboard;
