import type { ExoticComponent, HTMLAttributes, PropsWithChildren, ReactNode, RefAttributes } from 'react';
import { Children, useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import isEqual from 'lodash/isEqual';

import getFreeScrollSpace from '@/helpers/getFreeScrollSpace';
import { useIsMobile } from '@/hooks/useIsDevice';
import { type TOrientation } from '@/types/common';

import './styles.scss';

// Use "--grabbable-*" css variables on the parent node(s) to tune the appearance

type TNode = ExoticComponent<PropsWithChildren<HTMLAttributes<HTMLElement> & RefAttributes<HTMLElement>>>;

type TProps = HTMLAttributes<HTMLElement> & {
  children: ReactNode;
  className?: string;
  node?: string;
  shadows?: TOrientation;
  sliderClassName?: string;
  sliderNode?: string;
};

const Grabbable = ({ children, className, node, shadows, sliderClassName, sliderNode, ...restRootProps }: TProps) => {
  type TPoint = { left: number; top: number; x: number; y: number };
  const initialPosition = useRef<TPoint>({} as TPoint);
  const ref = useRef<HTMLElement>(null);
  const isMovementAllowed = useRef<boolean>(false);
  const isMovementRegistered = useRef<boolean>(false);
  const [freeSpace, setFreeSpace] = useState<Record<string, boolean>>();

  const hasChildren = !!Children.count(children);

  const onMouseMove = useCallback((event: Event) => {
    if (!isMovementAllowed.current) {
      return;
    }
    const clientX = (event as PointerEvent).clientX ?? (event as TouchEvent).touches?.[0]?.clientX;
    const clientY = (event as PointerEvent).clientY ?? (event as TouchEvent).touches?.[0]?.clientY;
    const dx = clientX - initialPosition.current!.x;
    const dy = clientY - initialPosition.current!.y;
    ref.current!.scrollLeft = initialPosition.current!.left - dx;
    ref.current!.scrollTop = initialPosition.current!.top - dy;
    isMovementRegistered.current = true;
  }, []);

  const onMouseDown = useCallback((event: Event) => {
    isMovementRegistered.current = false;
    isMovementAllowed.current = true;
    event.stopImmediatePropagation();
    initialPosition.current = {
      left: ref.current!.scrollLeft,
      top: ref.current!.scrollTop,
      x: (event as PointerEvent).clientX ?? (event as TouchEvent).touches?.[0]?.clientX,
      y: (event as PointerEvent).clientY ?? (event as TouchEvent).touches?.[0]?.clientY,
    };
  }, []);

  const onClick = useCallback((event: Event) => {
    if (isMovementRegistered.current) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }, []);

  const onMouseUp = useCallback(() => {
    isMovementAllowed.current = false;
  }, []);

  const isMobileDevice = useIsMobile();
  useEffect(() => {
    const node = ref?.current;
    if (node && hasChildren) {
      node.addEventListener(isMobileDevice ? 'touchstart' : 'mousedown', onMouseDown);
      node.addEventListener(isMobileDevice ? 'touchend' : 'mouseup', onMouseUp);
      node.addEventListener(isMobileDevice ? 'touchmove' : 'mousemove', onMouseMove);
      node.addEventListener('click', onClick);
      if (!isMobileDevice) node.addEventListener('mouseleave', onMouseUp);
      return () => {
        node.removeEventListener(isMobileDevice ? 'touchstart' : 'mousedown', onMouseDown);
        node.removeEventListener(isMobileDevice ? 'touchend' : 'mouseup', onMouseUp);
        node.removeEventListener(isMobileDevice ? 'touchmove' : 'mousemove', onMouseMove);
        node.removeEventListener('click', onClick);
        if (!isMobileDevice) node.removeEventListener('mouseleave', onMouseUp);
      };
    }
  }, [hasChildren]);

  useEffect(() => {
    const node = ref?.current;
    if (node && hasChildren && shadows) {
      const updateFreeSpace = (isForced?: boolean) => {
        if (isMovementAllowed.current || isForced) {
          const scrollSpace = getFreeScrollSpace(node!, shadows!);
          if (scrollSpace) {
            const next = Object.fromEntries(Object.entries(scrollSpace).map(([key, value]) => [`_${key}`, value > 0]));
            if (!isEqual(next, freeSpace)) setFreeSpace((prev) => (isEqual(next, prev) ? prev : next));
          }
        }
      };

      updateFreeSpace(true);
      const event = isMobileDevice ? 'touchmove' : 'mousemove';
      const listener = () => updateFreeSpace();
      node.addEventListener(event, listener);
      return () => node.removeEventListener(event, listener);
    }
  }, [hasChildren, shadows]);

  const Node = (node || 'div') as unknown as TNode;
  const Slider = (sliderNode || 'div') as unknown as TNode;
  return (
    hasChildren && (
      <Node {...restRootProps} className={cn('Grabbable', className, freeSpace)}>
        <Slider className={cn('Grabbable__slider', sliderClassName)} ref={ref}>
          {children}
        </Slider>
      </Node>
    )
  );
};

export default Grabbable;
