import type { TPromoBannerDoubleClickDetails, TPromoBannerDoubleProps, TWithOnClick } from '@/ducks/promoBanners/types';

import Grabbable from '@/components/Grabbable';

import HalfBanner from './BannerHalf';

import './index.scss';

type TProps = TWithOnClick<TPromoBannerDoubleProps, TPromoBannerDoubleClickDetails>;

const PromoBannerDouble = ({ appearance, onClick, one, overlayColor, two }: TProps) => {
  const commonProps = { appearance, onClick, overlayColor };

  return (
    <Grabbable shadows="horizontal" sliderClassName="PromoBannerDouble">
      <HalfBanner {...{ ...commonProps, ...one }} />
      <HalfBanner {...{ ...commonProps, ...two }} right />
    </Grabbable>
  );
};

export default PromoBannerDouble;
