import React from 'react';

import classNames from 'classnames';

import type { TOverlayColors, TStyleProps } from '@/ducks/promoBanners/types';

import styles from './PromoBannerLeaderboard.module.scss';

const PortHoleLeftSvg = (overlayColors: TOverlayColors) => (
  <svg
    className={styles.portHoleSvg}
    fill="none"
    height="260"
    viewBox="0 0 1194 160"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className={styles.portHoleCircle}
      cx="716.5"
      cy="79.5"
      r="452.5"
      stroke={overlayColors?.circleColor}
      stroke-opacity="0.75"
      stroke-width="40"
    />
    <path
      clip-rule="evenodd"
      d="M2087 -386H-635V546H2087V-386ZM716 531C965.633 531 1168 328.633 1168 79C1168 -170.633 965.633 -373 716 -373C466.367 -373 264 -170.633 264 79C264 328.633 466.367 531 716 531Z"
      fill={overlayColors?.color1}
      fill-rule="evenodd"
    />
  </svg>
);

const PortHoleCenterSvg = (overlayColors: TOverlayColors) => (
  <svg
    className={styles.portHoleSvg}
    fill="none"
    height="260"
    viewBox="0 0 1194 160"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className={styles.portHoleCircle}
      cx="587.5"
      cy="80.5"
      r="452.5"
      stroke={overlayColors?.circleColor}
      stroke-opacity="0.75"
      stroke-width="40"
    />
    <path
      clip-rule="evenodd"
      d="M1958 -386H-764V546H1958V-386ZM587 531C836.633 531 1039 328.633 1039 79C1039 -170.633 836.633 -373 587 -373C337.367 -373 135 -170.633 135 79C135 328.633 337.367 531 587 531Z"
      fill={overlayColors?.color1}
      fill-rule="evenodd"
    />
  </svg>
);

const PortHoleRightSvg = (overlayColors: TOverlayColors) => (
  <svg
    className={styles.portHoleSvg}
    fill="none"
    height="260"
    viewBox="0 0 1194 160"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className={styles.portHoleCircle}
      cx="475.5"
      cy="79.5"
      r="452.5"
      stroke={overlayColors?.circleColor}
      stroke-opacity="0.75"
      stroke-width="40"
    />
    <path
      clip-rule="evenodd"
      d="M1847 -386H-875V546H1847V-386ZM476 531C725.633 531 928 328.633 928 79C928 -170.633 725.633 -373 476 -373C226.367 -373 24 -170.633 24 79C24 328.633 226.367 531 476 531Z"
      fill={overlayColors?.color1}
      fill-rule="evenodd"
    />
  </svg>
);

export const PortHoleWrapper = ({ className, overlayColors }: TStyleProps) => {
  return (
    <div className={classNames(styles.porthole, className)}>
      {className === 'left' && <PortHoleLeftSvg {...overlayColors} />}
      {className === 'center' && <PortHoleCenterSvg {...overlayColors} />}
      {['right', 'trueRight'].includes(className) && <PortHoleRightSvg {...overlayColors} />}
    </div>
  );
};
