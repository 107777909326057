import type { TCurrencyCode } from '@/types';

import { CURRENCY_CODE_COOKIE } from '@/constants/settings';
import { selectSupportedCurrencies } from '@/ducks/common/lookup/selectors';
import { selectDefaultCurrencyCode } from '@/ducks/common/selectors';
import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '@/ducks/filters/types';
import { AGENCY_CURRENCY_COOKIE } from '@/ducks/fm/constants';
import { selectAgencyCurrencies, selectAgentCurrencyCode, selectIsAgencyDataAvailable } from '@/ducks/fm/selectors';
import { selectIsMnvv, selectMnvvCurrencyCode } from '@/ducks/mnvv/selectors';
import { getMnvvReservation } from '@/ducks/mnvv/utils';
import { setStoredValue } from '@/helpers/util';

type ConfigValue = FiltersConfigValue<FiltersTypes[FILTER_KEY.currencyCode]>;

const onValueUpdate: ConfigValue['onValueUpdate'] = ({ getCookie, setCookie, state, value: updatedValue }) => {
  const value = updatedValue || (selectDefaultCurrencyCode(state) as TCurrencyCode);
  setStoredValue('cmsCurrencyBroadcast', value);

  if (getCookie(CURRENCY_CODE_COOKIE) !== value.toString()) {
    setCookie(CURRENCY_CODE_COOKIE, value, { path: '/' });
  }

  if (selectIsAgencyDataAvailable(state) && getCookie(AGENCY_CURRENCY_COOKIE) !== value.toString()) {
    setCookie(AGENCY_CURRENCY_COOKIE, value, { path: '/' });
  }
};

const currencyCodeConfig: ConfigValue = {
  getValue: ({ getCookie, searchParams, state, value }) => {
    let currencyCode = value as TCurrencyCode | null;

    if (getMnvvReservation(searchParams).isMNVV) {
      currencyCode = selectMnvvCurrencyCode(state) as TCurrencyCode;
    } else if (selectIsAgencyDataAvailable(state)) {
      if (!currencyCode || !selectAgencyCurrencies(state)?.includes(currencyCode)) {
        currencyCode = selectAgentCurrencyCode(state) as TCurrencyCode;
      }
    }

    if (!currencyCode) {
      currencyCode = getCookie(CURRENCY_CODE_COOKIE) as TCurrencyCode;
    }

    if (!currencyCode) {
      currencyCode = selectDefaultCurrencyCode(state) as TCurrencyCode;
    }

    if (selectSupportedCurrencies(state).includes(currencyCode)) {
      return currencyCode;
    }

    return 'USD' as TCurrencyCode;
  },
  isValueValidForURL: ({ state, value }) => {
    value = (value as string)?.toUpperCase();
    return (
      !value ||
      // TODO: check invoice currency (?)
      ((!selectIsMnvv(state) || selectMnvvCurrencyCode(state) === value) &&
        (!selectIsAgencyDataAvailable(state) || selectAgencyCurrencies(state)?.includes(value as TCurrencyCode)) &&
        selectSupportedCurrencies(state).includes(value as TCurrencyCode))
    );
  },
  onValueInit: onValueUpdate,
  onValueUpdate,
};

export default currencyCodeConfig;
