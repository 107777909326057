import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import type { TStyleProps } from '@/ducks/promoBanners/types';

import styles from './PromoBannerLeaderboard.module.scss';

export const VstyleWrapper = ({ className, overlayColors }: TStyleProps) => {
  const [leftWidth, setLeftWidth] = useState(0);
  const [rightWidth, setRightWidth] = useState(0);
  const [leftGap] = useState(60);
  const [rightGap] = useState(60);

  useEffect(() => {
    const onResize = () => {
      const innerWidth = window?.innerWidth;
      let left = 0;
      let right = 0;
      if (className === 'center') {
        left = Math.round(innerWidth * 0.7);
        right = Math.round(innerWidth * 1.5);
        if (window.matchMedia('(min-width: 992px)').matches) {
          left = Math.round(innerWidth * 0.4);
          right = Math.round(innerWidth * 0.8);
        }
        if (window.matchMedia('(min-width: 1200px)').matches) {
          left = Math.round(innerWidth * 0.3);
          right = Math.round(innerWidth * 0.6);
        }
      }
      if (className === 'left') {
        left = Math.round(innerWidth * 0.9);
        right = Math.round(innerWidth * 0.6);
        if (window.matchMedia('(max-width: 575px)').matches) {
          left = 500;
          right = 100;
        }
      }
      if (className === 'right' || className === 'trueRight') {
        left = Math.round(innerWidth * 0.3);
        right = Math.round(innerWidth * 2.0);
        if (window.matchMedia('(min-width: 992px)').matches) {
          right = Math.round(innerWidth * 1.5);
        }
        if (window.matchMedia('(min-width: 1199px)').matches) {
          left = Math.round(innerWidth * 0.3);
          right = Math.round(innerWidth * 1.1);
        }
      }
      setLeftWidth(left);
      setRightWidth(right);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [className]);
  return (
    <div className={classNames(styles.vFrame, className)}>
      <svg
        className={styles.vStyle1}
        fill="none"
        height="270"
        viewBox="0 0 1111 160"
        width={leftWidth}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M1110.11 1651.51H-1155L-21.2043 -442L1110.11 1651.51Z"
          fill={overlayColors?.color1}
          fillRule="evenodd"
        />
      </svg>
      <svg
        className={styles.vStyle2}
        fill="none"
        height="270"
        viewBox="0 0 1131 160"
        width={leftWidth + leftGap}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M1130.11 1651.51H-1135L-1.20435 -442L1130.11 1651.51Z"
          fill={overlayColors?.color2}
          fillOpacity="0.6"
          fillRule="evenodd"
        />
      </svg>
      <svg
        className={styles.vStyle3}
        fill="none"
        height="270"
        viewBox="0 0 1151 160"
        width={leftWidth + 2 * leftGap}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M1150.11 1651.51H-1115L18.7957 -442L1150.11 1651.51Z"
          fill={overlayColors?.color2}
          fillOpacity="0.6"
          fillRule="evenodd"
        />
      </svg>
      <svg
        className={styles.vStyle4}
        fill="none"
        height="270"
        viewBox="0 0 949 160"
        width={rightWidth}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M2265.11 1651.51H0L1133.8 -442L2265.11 1651.51Z"
          fill={overlayColors?.color1}
          fillRule="evenodd"
        />
      </svg>
      <svg
        className={styles.vStyle5}
        fill="none"
        height="270"
        viewBox="0 0 969 160"
        width={rightWidth + rightGap}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M2265.11 1651.51H0L1133.8 -442L2265.11 1651.51Z"
          fill={overlayColors?.color2}
          fillOpacity="0.6"
          fillRule="evenodd"
        ></path>
      </svg>
      <svg
        className={styles.vStyle6}
        fill="none"
        height="270"
        viewBox="0 0 989 160"
        width={rightWidth + 2 * rightGap}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M2265.11 1651.51H0L1133.8 -442L2265.11 1651.51Z"
          fill={overlayColors?.color2}
          fillOpacity="0.6"
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
};
